import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useAccessCode from "../hooks/useAccessCode";
import { useNavigate } from "react-router-dom";
import { IHistoryReservaciones } from "../models/codes";
import { CustomModal } from "../components/Modal";
import { Home } from "@mui/icons-material";
import { TableHeaderCell } from "../components/Table/TableHeaderCell";
import { TableCell } from "../components/Table/TableCell";
import { Confirm } from '../components/Confirm';


export const HistorialReserva = () => {
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState<boolean>(false);


  const { getHistoryReservas, deleteReserva } = useAccessCode();

  const [historyR, setHistoryR] = useState<IHistoryReservaciones[]>([]);
  const [itemSelect, setItemSelect] = useState<IHistoryReservaciones>({} as IHistoryReservaciones);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
    navigate("/home/historial-reserva");
  };


  useEffect(() => {
    (async () => {
      let historyReservas = await getHistoryReservas();
      if (historyReservas) {
        setHistoryR(historyReservas);
      } else {
        setModalMessage("Error al obtener el historial de reservas");
        handleOpen();
      }
    })();
  });

  const AnularReserva = () => async () => {
    let amenidad = itemSelect;
    if(!amenidad) {
      return
    }
    console.log(amenidad);
    if (new Date(amenidad.fecha_Reservacion) <= new Date()) {
      setModalMessage(
        "No se puede anular la reserva,  la fecha de reservacion es menor a la actual"
      );
      handleOpen();
    } else {
      if (!confirm) {
        return;
      }
      const response = await deleteReserva(amenidad);
      if (response) {
        setModalMessage("Reserva anulada con éxito");

        handleOpen();
        setHistoryR(
          historyR.map(item =>
            item.num_Reserva === amenidad.num_Reserva
              ? { ...item, estado: "Anulada" }
              : item
          )
        );
      } else {
        setModalMessage("No es posible anular la reserva");
        handleOpen();
      }
    }
  };



  return (
    <>
    <Confirm
        message='Desea anular la reserva seleccionada?'
        okText='Anular'
        okAction={AnularReserva()}
        show={confirm}
        setShow={setConfirm}
      />
    <Box
      textAlign="center"
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="h4" component="div" gutterBottom marginY="1em">
        Historial de Reservas
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width={0.96}
        maxWidth={1000}
      >
        <TableContainer component={Box} sx={{ width: "100%" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell sx={{ whiteSpace: "nowrap", padding: 1 }}>
                  Amenidad
                </TableHeaderCell>
                <TableHeaderCell sx={{ whiteSpace: "nowrap", padding: 1 }}>
                  #{" "}
                </TableHeaderCell>
                <TableHeaderCell sx={{ whiteSpace: "nowrap", padding: 1 }}>
                  Fecha Hora{" "}
                </TableHeaderCell>
                <TableHeaderCell sx={{ whiteSpace: "nowrap", padding: 1 }}>
                  Valor
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historyR.length > 0 &&
                historyR.map(item => (
                  <TableRow
                    key={item.num_Reserva}
                    sx={{
                      cursor:
                        item.estado === "Vigente" &&
                        new Date(item.fecha_Reservacion) > new Date()
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() => {
                      if (
                        item.estado === "Vigente" &&
                        new Date(item.fecha_Reservacion) > new Date()
                      ) {
                        setItemSelect(item);
                        setConfirm(true);
                      }
                    }}
                  >
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        padding: 1,
                        color:
                          item.estado === "Anulada"
                            ? "rgb(255, 0, 0)"
                            : "rgb(0, 128, 0)",
                      }}
                    >
                      {item.amenidad}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        padding: 1,
                        color:
                          item.estado === "Anulada"
                            ? "rgb(255, 0, 0)"
                            : "rgb(0, 128, 0)",
                      }}
                    >
                      {item.num_Reserva}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        padding: 1,
                        color:
                          item.estado === "Anulada"
                            ? "rgb(255, 0, 0)" 
                            : "rgb(0, 128, 0)", 
                      }}
                    >
                      {new Date(item.fecha_Reservacion).toLocaleDateString(
                        "es-ES",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                        }
                      )}
                      <br />
                      {item.hora.replace(" ", "")}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        padding: 1,
                        color:
                        item.estado === "Anulada"
                        ? "rgb(255, 0, 0)" 
                        : "rgb(0, 128, 0)" 
                      }}
                    >
                      Q
                      {item.valor.toString().split(".").length === 2
                        ? item.valor
                        : item.valor.toString() + ".00"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <CustomModal openState={openModal} onCloseHandler={handleClose}>
        <Typography variant="h4" component="div" gutterBottom marginY="1em">
          {modalMessage}
        </Typography>

        <Button
          color="secondary"
          variant="outlined"
          endIcon={<Home />}
          sx={{ marginY: 2, width: "50%" }}
          onClick={handleClose}
        >
          Inicio
        </Button>
      </CustomModal>
    </Box>
    </>

  );
};
