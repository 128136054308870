import React, { useCallback, useRef, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import Webcam from 'react-webcam';
import { CameraAltOutlined, Refresh } from '@mui/icons-material';


const initialConstraints = {
  width: window.innerWidth - 30,
  height: 420,
  facingMode: "user"
};


export const FotosPruebas = ()  => {
  const cameraRef = useRef<any>(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [vidConstraints, setVidConstraints] = useState(initialConstraints);

  const tomarFoto = useCallback(() => {
    const srcImage = cameraRef?.current?.getScreenshot();
    setImgSrc(srcImage);
  }, [cameraRef, setImgSrc]);

  const changeModo = () => {
    if (vidConstraints?.facingMode === "user")
      setVidConstraints({...vidConstraints, facingMode: "environment"});
    else
      setVidConstraints({...vidConstraints, facingMode: "user"});
  };

  return (
    <Box textAlign="center" display="flex" alignItems="center" height={'100vh'} flexDirection='column'>
      <Typography variant="h4" component="div" gutterBottom marginY='1em'>
        Tomar Fotos Prueba
      </Typography>

      {imgSrc && (
        <img
          style={{
            margin: '0 2rem 4rem 2rem',
            borderRadius: '0.5rem',
            borderWidth: '1rem',
            borderColor: '#333'
          }}
          onClick={() => setImgSrc(null)}
          alt="Foto tomada"
          src={imgSrc}
        />
      )}

      <Webcam
        style={{
          borderRadius: '0.5rem',
          marginBottom: '4rem',
        }}
        screenshotFormat='image/jpeg'
        audio={false}
        ref={cameraRef}
        videoConstraints={vidConstraints}
      />

      <Box textAlign="center" display={'flex'} justifyContent={'space-around'} borderTop={'1px solid #AAA'} paddingY={'0.5rem'} width={'100%'} style={{ backgroundColor: '#FFF', position: 'sticky', bottom: 0 }}>
        <Button color='secondary' variant="outlined" endIcon={<CameraAltOutlined/>} sx={{width:'40%'}} onClick={tomarFoto}>
            Tomar Foto
        </Button>
        <Button color='secondary' variant="outlined" endIcon={<Refresh/>} sx={{width:'40%'}} onClick={changeModo}>
            Cambiar Modo
        </Button>
      </Box>
    </Box>
  )
}