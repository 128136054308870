import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { IAmenidad, IEventoCalendario } from '../../models/reservas';
import { useReservas } from '../../hooks/useReservas';
import { Disponibilidad } from './Disponibilidad';
import { SeleccionAmenidad } from './SeleccionAmenidad';
import { RevisionReserva } from './RevisionReserva';
import { Toast } from '../../components/Toast';
import { error } from 'console';

export const Reservas = () => {
  const [paso, setPaso] = useState(1);
  const [loadingEventos, setLoadingEventos] = useState(false);
  const [amenidad, setAmenidad] = useState<number>(-1);
  const [amenidadesList, setAmenidadesList] = useState<IAmenidad[]>([]);
  const [eventos, setEventos] = useState<IEventoCalendario[]>([]);
  const [toast, setToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('Default message');
  const [toastType, setToastType] = useState<string>('success');
  const [idReserva, setIdReserva] = useState<number>(-1);
  const { getListAmenidades, getEventos, addReserva, getListadoErroresReserva } = useReservas();

  const clearAllStates = () => {
    setEventos([]);
    setAmenidad(-1);
  }

  const notifyToast = (message: string, type: string) => {
    setToastMessage(message);
    setToastType(type);
    setToast(true);
    setTimeout(() => {
      setToast(false);
    }, 5000);
  }
  const pasos = [
    () => (
      <SeleccionAmenidad
        nextStep={() => setPaso(2)}
        nextText="Siguiente"
        amenidadesList={amenidadesList}
        setAmenidad={setAmenidad}
        amenidad={amenidad}
      />
    ),
    () => (
      <Disponibilidad
        prevStep={() => setPaso(1)}
        prevText="Anterior"
        nextStep={() => setPaso(3)}
        nextText="Siguiente"
        loadingEventos={loadingEventos}
        setEventos={setEventos}
        eventos={eventos}
        amenidadesList={amenidadesList}
        amenidad={amenidad}
      />
    ),
    () => (
      <RevisionReserva
        prevStep={() => setPaso(2)}
        prevText="Anterior"
        amenidad={amenidad}
        clearAction={clearAllStates}
        amenidadesList={amenidadesList}
        eventos={eventos}
        reservaAction={(evento) => addReserva(evento)}
        nextStep={() => setPaso(1)}
        setReservaError={setIdReserva}
      />
    )
  ];

  useEffect(() => {
    if (idReserva >= 1) {
      getListadoErroresReserva(idReserva)
      .then(listado => {
        const mainObject = listado.reduce((acc, curr) => {
          return ({nota: `${acc?.nota}, ${curr?.nota}`});
        });
        const errores:string = mainObject.nota;
        notifyToast(errores, 'success');
      })
      .catch((err) => {
        console.error(`[Reservas]: Error = ${JSON.stringify(err)}`);
        if (Object.keys(err).length === 0)
          notifyToast('Reserva realizada con exito!', 'success');
        else
          notifyToast(`Ha ocurrido un error: ${err?.message}`, 'error');
      })
    }
  }, [idReserva]);

  useEffect(() => {
    setLoadingEventos(true);
    getListAmenidades().then(response => {
      setAmenidadesList(response);
    });
  }, [])

  useEffect(() => {
    getEventos(amenidad).then(response => {
      setEventos(response);
      setLoadingEventos(false);
    });
  }, [amenidad])

  return (
    <Box textAlign="center" display="flex" alignItems="center" width={'100%'} height={'100vh'} flexDirection='column'>
      <Typography variant="h4" component="div" gutterBottom marginY='1em'>
        Reservas
      </Typography>

      {toast &&
        <Toast
          message={toastMessage}
          type={toastType}
        />
      }

      {pasos[paso - 1]()}

    </Box>
  )
}