import ReceiptPrinterEncoder from '@point-of-sale/receipt-printer-encoder'

export let thermalPrinterEncoder = new ReceiptPrinterEncoder({
    feedBeforeCut: 6,
})

let printerDevice = null

export async function ThermalPrinter(data) {


    if ('bluetooth' in navigator) {

        if (printerDevice === null) { 
            printerDevice = await navigator.bluetooth.requestDevice({
                filters: [{
                    services: ['000018f0-0000-1000-8000-00805f9b34fb']
                }]
            })
        }
        //console.log('Bluetooth device selected:', printerDevice)
        printerDevice.gatt.connect().then(server => {
            //console.log('Bluetooth device connected:', server)
            server.getPrimaryService('000018f0-0000-1000-8000-00805f9b34fb').then(service => {
                //console.log('Printing service found:', service)
                service.getCharacteristic("00002af1-0000-1000-8000-00805f9b34fb").then(characteristic => {
                    //console.log('Printing characteristic found:', characteristic)
                    
                    characteristic.writeValue(data)
                    .then(() => {
                        console.log('Data sent successfully')
                    })
                    .catch(error => {
                        console.error('Error sending data:', error)
                        alert('Error en al conectar con la impresora, intenta de nuevo para sincronizar la impresora')
                        printerDevice = null
                    })
                })
                .catch(error => {
                    console.error('Error retrieving characteristics:', error)
                })
            })
            .catch(error => {
                console.error('Error retrieving service:', error)
            })
        })
        .catch(error => {
            console.error('Error connecting:', error)
            alert('Error en al conectar con la impresora, intenta de nuevo para sincronizar la impresora')
            printerDevice = null
        })
    }
    else {
        console.error("Bluetooth is not supported")
    }
}