import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';

export const Footer = () => {

  const theme = useTheme();

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      paddingTop: "0.25rem",
      position: "fixed",
      bottom: 0,
      width: "100%",
      paddingBottom: "0.25rem",
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    }}>

      
      <Grid container spacing={2} justifyContent="center" alignItems="center">
          
        <h4>Contáctanos</h4>
        
        <Grid container justifyContent='center' alignItems="center" direction='row'>

          <Box width={35} marginX={4} className='scale-up-hover'>
            <a href='https://www.ls-sys.com' >
              <img src='assets\globe.png' alt='Website' width='100%' />
            </a>
          </Box>
          
          <Box width={40} marginX={4} className='scale-up-hover'>
            <a href='mailto:ventas@ls-sys.com' >
              <img src='assets\email.png' alt='Email' width='100%' />
            </a>
          </Box>

        </Grid>

        <Typography variant="body2" align="center" component="p">
          v2.0{window.location.origin === 'https://logicsoft-app-testing.netlify.app' ? '-dev' : ''}
        </Typography>

      </Grid>

    </Box>
  )
}
