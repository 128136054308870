import lsApi from "../api/lsApi";
import { useAppSelector } from '../redux/hooks';
import { ICreateDeleteCodeResponse, IMotive, IVerifyCodeResponse, IHistoryAccessCode, IHistoryReservaciones} from '../models/codes';

export default function useAccessCode() {

    const userData = useAppSelector((state) => state.auth.userData)

    const createAccessCode = async (nombre:string,motivo:string,fecha:string,fechaAbierta:number,limite:number,nota:string) => {

        const {agencia,casa,empresa,usuario} = userData!

        try{
            const { data, status } = await lsApi.post<ICreateDeleteCodeResponse>('/api/ControlAcceso/CrearAcceso', { 
                empresa,
                agencia,
                id_casa : casa,
                nombre_Visita : nombre,
                motivo_Visita : motivo,
                fecha_Visita : fecha,
                fecha_Abierta : fechaAbierta,
                nota : nota,
                usuario : usuario,
                limite : limite,
                uuid : `acb3f5c0-5b9f-11eb-ae93-0242ac130002`,            
            })

            if (status === 200) {
                return data
            }
            else{
                return false
            }
            
        }
        catch (error) {
            return false
        }

    }

    const deleteAccessCode = async (codigo:string) => {

        const {agencia,casa,empresa,usuario} = userData!

        try{
            const { data,status } = await lsApi.delete<ICreateDeleteCodeResponse>('/api/ControlAcceso/AnularCodigoAcceso',{
                data : {
                    empresa,
                    agencia,
                    codigo_Acceso : codigo,
                    id_casa : casa,
                    usuario : usuario,
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (status === 200) {
                return data
            }
            else{
                return false;
            }
        }
        catch (error) {
            console.log(error)
            return false
        }

    }

    const validateAccessCode = async (codigo:string) => {

        const {agencia,casa,empresa,usuario} = userData!

        try{
            const { data,status } = await lsApi.put<IVerifyCodeResponse>('/api/ControlAcceso/ValidarCodigoAcceso', {
                empresa,
                agencia,
                codigo_Acceso : codigo,
                usuario : usuario,
                id_casa : casa,
            })

            if (status === 200 ) {
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            console.log(error)
            return false
        }

    }
    
    const verifyAccesCode = async (correlativo:number, placa:string, vehiculo:string, color:string, documento:string) => {

        const {agencia,empresa,usuario} = userData!

        try{
            const { data,status } = await lsApi.put<ICreateDeleteCodeResponse>('/api/ControlAcceso/AceptarEntradaSalida', {
                entrada: true,
                empresa,
                agencia,
                correlativo,
                placa,
                vehiculo,
                color,
                documento,
                usuario,
            })

            if (status === 200) {
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            console.log(error)
            return false
        }
    }

    const giveEgress = async (correlativo:number, placa:string, vehiculo:string, color:string, documento:string) => {

        const {agencia,empresa,usuario} = userData!

        try{
            const { data,status } = await lsApi.put<ICreateDeleteCodeResponse>('/api/ControlAcceso/AceptarEntradaSalida', {
                entrada: false,
                empresa,
                agencia,
                correlativo,
                placa,
                vehiculo,
                color,
                documento,
                usuario,
            })

            if (status === 200) {
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            console.log(error)
            return false
        }
    }

    const getMotives = async () => {

        const {empresa} = userData!

        try{
            const { data, status } = await lsApi.get<IMotive[]>(`/api/ControlAcceso/CatalogoTipoMotivo?Empresa=${empresa}`)
            if (status === 200) {
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            return false
        }

    }

    const getHistory = async () => {

        try{
            const { data, status } = await lsApi.get<IHistoryAccessCode[]>(`/api/ControlAcceso/ListadoCodigoAcceso`)
            if (status === 200) {
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            return false
        }
    }

    const getHistoryEgress = async () => {

        try{
            const { data, status } = await lsApi.get<IHistoryAccessCode[]>(`/api/ControlAcceso/EgresoGarita`)
            if (status === 200) {
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            return false
        }
    }

    const getHistoryReservas = async () => {

        try{
            const { data, status } = await lsApi.get<IHistoryReservaciones[]>(`/api/ControlAcceso/ListadoReservaciones`)
            if (status === 200) {
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            return false
        }
    }
    const deleteReserva = async (reserva:IHistoryReservaciones) => {


        try{
            const { data, status } = await lsApi.put<ICreateDeleteCodeResponse>(
                '/api/ControlAcceso/AnularReservaAmenidad',
                reserva,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )

            if (status === 200) {
                return data
            }
            else{
                return false;
            }
        }
        catch (error) {
            console.log(error)
            return false
        }

    }

    return {
        createAccessCode,
        deleteAccessCode,
        validateAccessCode,
        verifyAccesCode,
        getMotives,
        getHistory,
        getHistoryEgress,
        giveEgress,
        getHistoryReservas,
        deleteReserva
    }



}

