import lsApi from "../api/lsApi";
import { useAppSelector } from '../redux/hooks';
import { IEventoCalendario, IAmenidad, IEventoCalendarioAPI,
  IEventoCalendarioRequestBody, IErrorReservas
} from '../models/reservas';

export function useReservas() {
  const userData = useAppSelector((state) => state.auth.userData);
  const { nombre, usuario, empresa, agencia, casa: numCasa } = userData!;
  const propietario = nombre;
  const casa = usuario.slice(2);
  const newDate = new Date();
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const fecha = `${newDate.getFullYear()}-${month}-01`;

  const getListAmenidades = async () => {
    try {
      const { status, data } = await lsApi.get<IAmenidad[]>(`/api/ControlAcceso/ListadoAmenidades`);
      if (status === 200) {
        return data;
      } else {
        return [
          {
            "servicio": -1,
            "nombre": "-- Algo salio mal al cargar amenidades --",
            "valor": 0,
            "tiempo_Maximo": 0,
            "inicio_Amenidad": 0,
            "fin_Amenidad": 0
          }
        ];
      }
    } catch (error) {
      console.error(error);
      return [
        {
          "servicio": -1,
          "nombre": "-- Algo salio mal al cargar amenidades --",
          "valor": 0,
          "tiempo_Maximo": 0,
          "inicio_Amenidad": 0,
          "fin_Amenidad": 0
        }
      ];
    }
  }

  const getEventosFake = async () => {
    const fetchApi = () => new Promise<IEventoCalendario[]>(resolve => {
      setTimeout(() => {
        resolve([
          {
            id: 'R#09',
            title: 'All-day event',
            start: '2023-03-11'
          },
          {
            id: 'R#20',
            title: 'Timed event',
            start: '2023-03-12T12:00:00'
          },
          {
            id: 'R#21',
            title: 'Cumple event',
            start: '2023-03-13T14:00:00',
            end: '2023-03-13T16:00:00'
          }
        ])
      }, 2000)
    });
    const eventos = await fetchApi();
    return eventos;
  }

  const getEventos = async (inServicio:number) => {
    try {
      const [INEMPRESA, DEMES, INANIO] = [empresa, Number(month), newDate.getFullYear()];
      const { status, data } = await lsApi.get<IEventoCalendarioAPI[]>(
          `/api/ControlAcceso/Reservaciones?INEMPRESA=${INEMPRESA}&DEMES=${DEMES}&INANIO=${INANIO}&INSERVICIO=${inServicio}`
        );
      if (status === 200) {
        const eventos:IEventoCalendario[] = data.map((evento:IEventoCalendarioAPI) => {
          return {
            id: evento.id_Reserva.toString(),
            title: evento.nombre,
            start: evento.inicio,
            end: evento.fin
          }
        });
        return eventos;
      } else {
        console.error(`Algo provoco un status ${status}. Data: ${data}`);
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getListadoErroresReserva = async (idReserva: number) => {
    try {
      const { status, data } = await lsApi.get<IErrorReservas[]>(
        `/api/ControlAcceso/ListadoErroresReserva?INRESERVA=${idReserva}`
      );
      if (status === 200)
        return data;
      else {
        console.error(`Algo provoco un status ${status}. Data: ${data}`);
        return [
          {
            nota: `Algo provoco un status ${status} con estos datos: ${data}`
          }
        ]
      }
    }
    catch (err:any) {
      console.error(JSON.stringify(err));
      return [
        {
          nota: `Algo provoco un error! ${err.message ? err.message : ''}`
        }
      ]
    }
  }

  const addReserva = async (eventoData) => {
    const { servicio, fecha, fecha_Reservacion, inicio_Amenidad, fin_Amenidad } = eventoData;
    const evento:IEventoCalendarioRequestBody = {
      empresa,
      agencia,
      casa: numCasa,
      id_Reserva: 0,
      servicio,
      fecha,
      fecha_Reservacion,
      inicio_Amenidad,
      fin_Amenidad,
      usuario
    };
    const { status, data } = await lsApi.post(
      `/api/ControlAcceso/CrearReserva`,
      evento
    );
    if (status === 200)
      return data;
    else
      return false;
  };

  return {
    getListAmenidades,
    getEventos,
    getEventosFake,
    addReserva,
    getListadoErroresReserva,
    propietario,
    casa,
    fecha
  };
}