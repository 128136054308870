import { useState, useEffect } from 'react';
import { Box, Table, TableBody, Button, TableHead, TableRow, Typography } from '@mui/material';
import { Download } from '@mui/icons-material';
import { useEstadoCuenta } from '../hooks/useEstadoCuenta';
import { IPaymentStatement } from '../models/payments';
import { getMonthNameByDate } from '../utils/date';
import { TableCell } from '../components/Table/TableCell';
import { TableHeaderCell } from '../components/Table/TableHeaderCell';
import { TableFooter } from '../components/Table/TableFooter';
import { formatearNumero } from '../utils/numbers';
import { EstadoPDF } from '../components/EstadoPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';

export const EstadoCuenta = () => {

  const { getEstadoCuenta, propietario, casa, condominio } = useEstadoCuenta();

  const [date, setFecha] = useState<string>(`${new Date().toISOString().slice(0, 7)}-01`);

  const currentYear = Number(date.split('-')[0]);
  const currentMonth = Number(date.split('-')[1]);
  const displayDate = `${getMonthNameByDate(new Date(currentYear, currentMonth - 1, 1))}-${currentYear}`;

  const [estadoCuentaData, setEstadoCuentaData] = useState<IPaymentStatement[]>([]);
  const errorEstadoCuentaObject: IPaymentStatement = {
    id: 'error-ocurred',
    fecha: '00/00/0000',
    descripcion: `Ocurrió un error`,
    type: 'abono',
    monto: 0
  };

  useEffect(() => {
    (async () => {
      console.log(date);
      const estadoCuenta = await getEstadoCuenta(date);
      if (estadoCuenta) {
        // Ordenar como vienen los datos del Backend para que este en orden al iterar.
        const anterior = estadoCuenta.find(data => data.type === 'anterior') ?? errorEstadoCuentaObject;
        const actual = estadoCuenta.find(data => data.type === 'actual') ?? errorEstadoCuentaObject;
        const transacciones = estadoCuenta.filter(data => data.type !== 'actual' && data.type !== 'anterior') ?? errorEstadoCuentaObject;
        const sortedEstadoCuenta = [anterior, ...transacciones, actual];
        setEstadoCuentaData(sortedEstadoCuenta);
      } else {
        setEstadoCuentaData([errorEstadoCuentaObject]);
      }
    })();
  }, [date]);

  return (
    <Box textAlign="center" display="flex" alignItems="center" flexDirection='column' paddingBottom={'10rem'}>
      <Typography variant="h4" component="div" gutterBottom marginY='1em'>
          Estado de Cuenta
      </Typography>

      <Typography component="div" gutterBottom textAlign='left' width={'100%'} style={{ textIndent: '2rem' }} marginY='0.5em'>
        {casa} - {propietario}
      </Typography>
      <Typography component="div" textAlign='left' width={'100%'} style={{ textIndent: '2rem' }} marginY='0.5em'>
        Mostrar desde: 
        <input type="month" id="start" name="start" min="2020-01" max="2030-12" defaultValue={new Date(currentYear, currentMonth - 1).toISOString().slice(0, 7)}
        style={{
          fontSize: '1rem',
          padding: '0.5em',
          borderRadius: '5px',
          border: '1px solid #AAA',
          margin: '0.5em'
        }} onChange={(e) => { setFecha(`${e.target.value}-01`) }}
        />
      </Typography>

      <Box display="flex" flexDirection="column" alignItems='center' width={0.8} maxWidth={800} >
        <Table stickyHeader>
          <TableHead>
              <TableRow>
                  <TableHeaderCell>Fecha</TableHeaderCell>
                  <TableHeaderCell>Descripción</TableHeaderCell>
                  <TableHeaderCell>Monto</TableHeaderCell>
              </TableRow>
          </TableHead>
          <TableBody>
              {estadoCuentaData.length > 0 && estadoCuentaData.map((data,index) => (

                  index === 0 ?
                  <TableRow key={Number(data.id) === -1 ? Math.floor(Math.random() * 999) + 600 : data.id}>
                      <TableCell> </TableCell>
                      <TableCell><b>{data.descripcion}</b></TableCell>
                      <TableCell sx={{textAlign:'right'}}><b>{formatearNumero(data.type === 'abono' ? data.monto*-1 : data.monto)}</b></TableCell>
                  </TableRow>
                  : index === estadoCuentaData.length-1 ?
                  <TableRow key={Number(data.id) === -1 ? Math.floor(Math.random() * 999) + 600 : data.id} >
                      <TableFooter></TableFooter>
                      <TableFooter>{data.descripcion}</TableFooter>
                      <TableFooter sx={{textAlign:'right'}}>{formatearNumero(data.type === 'abono' ? data.monto*-1 : data.monto)}</TableFooter>
                  </TableRow>
                  :
                  <TableRow key={Number(data.id) === -1 ? Math.floor(Math.random() * 999) + 600 : data.id}>
                      <TableCell>{data.fecha}</TableCell>
                      <TableCell>{data.descripcion}</TableCell>
                      <TableCell sx={{textAlign:'right'}}>{formatearNumero(data.type === 'abono' ? data.monto*-1 : data.monto)}</TableCell>
                  </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <Box textAlign="center" borderTop={'1px solid #AAA'} position="fixed" bottom='0' width={'100%'} style={{ backgroundColor: '#FFF' }}>
      {
        estadoCuentaData.length > 0 &&
        <PDFDownloadLink document={<EstadoPDF estadoData={estadoCuentaData} propietario={propietario} condominio={condominio} casa={casa} strFecha={displayDate} />} fileName={`EstadoCuenta-${displayDate}-${casa}.pdf`}>
        <Button color='secondary' variant="outlined" endIcon={<Download/>} sx={{marginY:2 , width:'50%'}}>
              Descargar
        </Button>
        </PDFDownloadLink>
      }
      </Box>
    </Box>
  );

  

}