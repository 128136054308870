import lsApi from "../api/lsApi";
import { useAppSelector } from '../redux/hooks';
import { IPaymentStatement } from '../models/payments';

export function useEstadoCuenta() {
  const userData = useAppSelector((state) => state.auth.userData);
  const { nombre, usuario, nombre_Empresa } = userData!;

  const getEstadoCuenta = async (fecha: string) => {
    try {
      const { status, data } = await lsApi.get<IPaymentStatement[]>(`/api/ControlAcceso/EstadoCuenta?DAFECHAI=${fecha}&VACASA=${usuario}`);
      if (status === 200) {
        
        data.forEach((element) => {
          element.fecha = element.fecha.split('-').reverse().join('-');
        });

        return data;
      } else {
        return [
          {
            id: status.toString(),
            fecha,
            descripcion: `status ${status}`,
            type: 'abono',
            monto: status
          }
        ];
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  return {
    getEstadoCuenta,
    propietario: nombre,
    casa : usuario.slice(2),
    condominio: nombre_Empresa
  };
}