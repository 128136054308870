import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { IEncuesta } from '../../models/encuestas';
import { useEncuestas } from '../../hooks/useEncuestas';
import { Loader } from '../../components/Loader';
import { scrollToId } from '../../utils/utils';

interface IDetalleEncuestaProps {
  idEncuesta: number;
  onBack: () => void;
}

interface ICurrentQuestionData {
  questionCount: number;
  nextQuestionId: string;
  prevQuestionId: string;
}
interface ICurrentQuestion {
  id: string;
  data: ICurrentQuestionData;
}

const TIPOS_RESPUESTA = {
  4: 'radio'
}

const INITIAL_QUESTION_ID = 'TOP';


export const DetalleEncuesta = ({ idEncuesta, onBack }: IDetalleEncuestaProps) => {
  const [encuesta, setEncuesta] = useState<IEncuesta[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState<ICurrentQuestion>({
    id: INITIAL_QUESTION_ID,
    data: {
      questionCount: 0,
      nextQuestionId: '',
      prevQuestionId: ''
    }
  });
  const [questionsIdsArray, setQuestionsIdsArray] = useState<string[]>([]);
  const [questionsQuantity, setQuestionsQuantity] = useState<number>(0);
  const [answeringLastQuestion, setAnsweringLastQuestion] = useState<boolean>(false);
  const [encuestaStarted, setEncuestaStarted] = useState<boolean>(currentQuestion.id !== "TOP");
  const { getDetalleEncuesta } = useEncuestas();

  const getQuestionsQuantityFromData = (data: IEncuesta[]) => {
    let quantity = 0;
    data.forEach(encuesta =>
      encuesta?.grupos.forEach(grupo => quantity += grupo?.preguntas?.length)
    )
    return quantity;
  };

  const handleIniciarEncuesta = () => {
    const actualQuestionCount: number = currentQuestion.data.questionCount;
    scrollToId(currentQuestion.data.nextQuestionId);
    setEncuestaStarted(true);
    setCurrentQuestion({
      ...currentQuestion,
      id: questionsIdsArray[actualQuestionCount + 1],
      data: {
        ...currentQuestion.data,
        questionCount: actualQuestionCount + 1,
        nextQuestionId: questionsIdsArray[actualQuestionCount + 2],
        prevQuestionId: "TOP"
      }
    });
    setAnsweringLastQuestion(currentQuestion?.data?.questionCount === questionsQuantity);
  };

  const handleAtras = () => {
    const actualQuestionCount: number = currentQuestion.data.questionCount;
    const actualId: string = questionsIdsArray[actualQuestionCount - 1] ?? 'no';
    const prevId: string = questionsIdsArray[actualQuestionCount - 2] ?? '';
    const nextId: string = questionsIdsArray[actualQuestionCount];
    if(actualId === "TOP")
      setEncuestaStarted(false);
    // Ejecutar PUT request para guardar respuesa
    scrollToId(currentQuestion?.data?.prevQuestionId);
    setCurrentQuestion({
      ...currentQuestion,
      id: actualId,
      data: {
        ...currentQuestion.data,
        questionCount: actualQuestionCount - 1,
        nextQuestionId: nextId,
        prevQuestionId: prevId
      }
    })
  };

  const handleSiguiente = () => {
    const actualQuestionCount: number = currentQuestion.data.questionCount;
    const actualId: string = questionsIdsArray[actualQuestionCount + 1];
    const prevId: string = questionsIdsArray[actualQuestionCount];
    const nextId: string = questionsIdsArray[actualQuestionCount + 2];
    if(!answeringLastQuestion) {
      // Ejecutar PUT request para guardar respuesta
      scrollToId(currentQuestion?.data?.nextQuestionId);
      setCurrentQuestion({
        ...currentQuestion,
        id: actualId,
        data: {
          ...currentQuestion.data,
          questionCount: actualQuestionCount + 1,
          nextQuestionId: nextId,
          prevQuestionId: prevId
        }
      })
    } else {
      onBack();
    }
  };

  const buildQuestionsIdsArray = (encuesta: IEncuesta[]) => {
    let questionsIdsArray: string[] = ["TOP"];
    encuesta.forEach(encuesta =>
      encuesta.grupos.forEach(grupo =>
        grupo.preguntas.forEach(pregunta =>
          questionsIdsArray = [...questionsIdsArray, `${grupo.id}_${pregunta.num_Pregunta}`]
        )
      )
    );
    setQuestionsIdsArray(questionsIdsArray);
    if (currentQuestion.id === INITIAL_QUESTION_ID) {
      setCurrentQuestion({
        ...currentQuestion,
        data: {
          ...currentQuestion.data,
          nextQuestionId: questionsIdsArray[1],
          prevQuestionId: ''
        }
      });
    }
  }

  // useEffect(() => {
  //   console.log(JSON.stringify(currentQuestion, null, 2));
  //   console.log(JSON.stringify(questionsIdsArray, null, 2));
  // }, [currentQuestion, questionsIdsArray]);

  useEffect(() => {
    setLoading(true);
    getDetalleEncuesta(idEncuesta)
    .then(data => {
      setLoading(false);
      setEncuesta(data);
      const questionCount = getQuestionsQuantityFromData(data);
      setQuestionsQuantity(questionCount);
    })
    .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    buildQuestionsIdsArray(encuesta);
  }, [encuesta]);

  useEffect(() => {
    setAnsweringLastQuestion(currentQuestion.data.questionCount === questionsQuantity)
  }, [currentQuestion.data.questionCount, questionsQuantity]);

  if(loading)
    return <div style={{marginTop: '3rem'}}>
      <Loader loadingText='Cargando encuesta...'/>
    </div>

  return (
    <Box id="TOP">
      {encuesta.map(encuestaData => {
        return (
          <Box
            key={encuestaData.id}
            textAlign="center"
            display="flex"
            alignItems="center"
            width={'100%'}
            flexDirection='column'
          >
            <Box
              height={'100vh'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'start'}
              alignItems={'stretch'}
              paddingTop={'8rem'}
            >
              <Typography variant="h4" component="div" gutterBottom marginY={'1.5rem'}>
                {encuestaData.nombre}
              </Typography>
              <Box>
                <Button variant='contained' onClick={handleIniciarEncuesta}>
                  Iniciar
                </Button>
              </Box>
            </Box>
            {encuestaData.grupos.map((grupo) => {
              return (
                <Box
                  key={grupo.id}
                  textAlign={'left'}
                  width={'94%'}
                  marginBottom={'1rem'}
                  borderRadius={'5px'}
                  style={{
                    backgroundColor: '#01346710'
                  }}
                >
                  {/* Grupo */}
                  <Typography
                    variant="h5"
                    component="div"
                    gutterBottom
                    textAlign={'left'}
                    padding={'1rem'}
                    paddingBottom={'1rem'}
                    position={'sticky'}
                    top={0}
                    style={{
                      backgroundColor: 'transparent'
                    }}
                  >
                    {grupo.nombre}
                  </Typography>
                  <Box>
                    {grupo.preguntas.map((pregunta) => {
                      return (
                        <Box
                          key={`${grupo.id}-${pregunta.num_Pregunta}`}
                          id={`${grupo.id}_${pregunta.num_Pregunta}`}
                          padding={'1rem'}
                          height={'100vh'}
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'center'}
                          alignItems={'stretch'}
                        >
                          {/* ------- Pregunta ------- */}
                          <Typography
                            variant='body1'
                            component='p'
                            gutterBottom
                            textAlign={'left'}
                            title={pregunta.help_Text}
                          >
                            {pregunta.texto_Pregunta}
                          </Typography>
                          <Box>
                            <fieldset
                              style={{
                                border: 0,
                                display: 'flex',
                                justifyContent: 'space-around'
                              }}
                            >
                              {pregunta.opciones?.map(opcion => (
                                <div key={opcion.opcion}>
                                  <label>{opcion.texto}</label>
                                  <input
                                    name={`pregunta_${grupo.id}_${pregunta.num_Pregunta}`}
                                    type={TIPOS_RESPUESTA[pregunta.tipo_Respueta]}
                                    value={opcion.texto}
                                  />
                                </div>
                              ))}
                            </fieldset>
                          </Box>
                          <Box
                            width={'100%'}
                            paddingY={'1rem'}
                            display={encuestaStarted ? 'flex' : 'none'}
                            justifyContent={'space-around'}
                            alignSelf={'flex-end'}
                            // position={'absolute'}
                            // bottom={0}
                            // zIndex={8}
                            style={{
                              backgroundColor: 'transparent'
                            }}
                          >
                            <Button color='primary' variant='outlined' onClick={handleAtras}
                              style={{ width: '40%' }}
                            >
                              Atras
                            </Button>
                            <Button
                              color='primary'
                              variant={answeringLastQuestion ? 'contained' : 'outlined'}
                              onClick={handleSiguiente}
                              style={{ width: '40%' }}
                            >
                              {answeringLastQuestion ? 'Finalizar' : 'Siguiente'}
                            </Button>
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}