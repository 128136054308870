import React, { useState } from 'react'
import { Button, Card, CardContent, Chip, TextField, Typography } from '@mui/material';
import { ILecturaAgua } from '../models/lecturas'
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { updateItem } from '../redux/readSlice';
import { useLecturas } from '../hooks/useLecturas';
import { ThermalPrinter, thermalPrinterEncoder } from '../utils/printer';

interface LecturaAguaItemProps {
    lectura: ILecturaAgua
}

export const LecturaAguaItem = (props: LecturaAguaItemProps) => {

    const { lectura } = props

    const { sendLectura } = useLecturas()

    const { nombre_Empresa } = useAppSelector((state) =>  state.auth.userData!)
    const { lect_Actual, lect_Anterior, limite_M3, num_Casa, agua_derecho } = lectura
    const [lecturaActual, setLecturaActual] = useState(lect_Actual)
    const [consumo, setConsumo] = useState(-1)

    const dispatch = useAppDispatch()

    const handleGuardar = async () => {

        const consumoTemp = lecturaActual - lect_Anterior

        if(consumoTemp < 0){
            alert('La lectura actual no puede ser menor a la anterior')
            return
        }
        else if(consumoTemp > limite_M3){
            if(!window.confirm(`El consumo es de ${consumoTemp.toFixed(2)} m3, y excede el limite de ${limite_M3} m3. ¿Esta seguro de guardar el dato?`)){
                return
            }
        }
        else if(consumoTemp==0){
            if(!window.confirm('La lectura actual es igual a la anterior. ¿Esta seguro de guardar el dato?')){
                return
            }
        }
        setConsumo(consumoTemp)

        const req = await sendLectura({
            ...lectura,
            lect_Actual: lecturaActual
        })

        if (req == 'offline') {
            dispatch(updateItem({
                ...lectura,
                lect_Actual: lecturaActual,
                estado: 'Guardado Offline'
            }))
        }
        else if (req && req.success){
            dispatch(updateItem({
                ...lectura,
                lect_Actual: lecturaActual,
                estado: 'Guardado'
            }))
        }
        else{
            alert('No se pudo guardar la lectura')
        }
    }

    const handlePrint = async () => {
        
        let data = thermalPrinterEncoder
        .initialize()
        .height(2)
        .line('        Lectura de Agua      ')
        .line(`       ${nombre_Empresa}     `)
        .newline()
        .align('left')
        .line(`Casa:              ${num_Casa}`)
        .line(`Fecha:             ${new Date().toLocaleDateString()}`)
        .line(`Hora:              ${new Date().toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})}`)
        .line(`Lectura Anterior:  ${lect_Anterior}`)
        .line(`Lectura Actual:    ${lecturaActual}`)
        .line(`Consumo:           ${consumo.toFixed(2)} m3`)
        .line(`Exceso:            ${consumo>agua_derecho? (consumo-agua_derecho).toFixed(2):0} m3`)
        .encode()

        ThermalPrinter(data)
    }

    return (
        <Card  
            sx={{
                width: { xs: '70%', md: '30%' },
                margin: '1em',
                padding: '2em',
            }}
        >
            <CardContent>
                
                {lectura.estado === undefined && <Chip label="Sin guardar" color="error" size="small" sx={{alignSelf:'end',minWidth:'30%'}}/>}
                {lectura.estado === 'Guardado' && <Chip label="Guardado" color="success" size="small" sx={{alignSelf:'end',minWidth:'30%'}}/>}
                {lectura.estado === 'Guardado Offline' && <Chip label="Guardado Offline" color="warning" size="small" sx={{alignSelf:'end',minWidth:'30%'}}/>}

                <Typography sx={{ my: 1.5 }} color="text.secondary">
                    Casa {num_Casa}
                </Typography>

                <Typography variant="h6" component="div">
                    Lectura Anterior: {lect_Anterior}
                </Typography>

                <TextField fullWidth required type='number' defaultValue={lecturaActual} label="Lectura Actual" variant="outlined" 
                    sx={{marginY:2}} 
                    onChange={(e) => setLecturaActual(parseFloat(e.target.value))}
                    InputProps={{
                        inputProps: {
                            min: 0,
                        }
                    }}
                />

                <Button color='secondary' variant="contained" sx={{marginY:2 , width:'40%'}} onClick={handleGuardar}>
                    Guardar
                </Button>
                
                <br/>

                {
                    (lectura.estado === 'Guardado' || lectura.estado === 'Guardado Offline') &&
                    <Button color='info' variant="contained" sx={{marginY:2 , width:'40%'}} onClick={handlePrint}>
                        Imprimir Recibo
                    </Button>
                }

                {consumo >= 0 &&
                    <Typography variant="h6" sx={{ mt: 1.5 }} component="div">
                        Consumo: {consumo.toFixed(2)} m3
                    </Typography>
                }

            </CardContent>
            
        </Card>
    )
}
